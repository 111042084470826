import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import Button from '@mui/material/Button'
import './Detail.scss'
import CardDetail from './CardDetail'
import Map from './Map'
import TextField from '@mui/material/TextField'
import Points from './Points'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import DeleteOrder from '../Modals/DeleteOrder'
import AddPoint from '../EditPoint/Edit'
import { useNavigate, useParams } from 'react-router-dom'
import { orderSelector } from '../../selectors/OrderSelectors'
import * as orderActions from '../../actions/OrderActions'
import moment from 'moment'
import 'moment/locale/es'
import { NotificationContainer } from 'react-notifications'
import { Tooltip } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
// import useWebsocket from "../../useWebsocket";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
    color: '#3F4D55',
    '& > svg': {
      margin: '2rem'
    },
    flexGrow: 1,
    padding: '40px 40px',
    marginTop: '60px',
    marginLeft: '12%',
    marginBottom: '10px',
    backgroundColor: 'white'
  },
  paper: {
    padding: '2rem',
    textAlign: 'center',
    color: '#FFFF'
  },
  textCenter: {
    textAlign: 'center'
  },
  button: {
    background: '#0a50e6',
    color: 'white'
  },
  gridCancel: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

// load google map script
const loadGoogleMapScript = (callback) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    callback()
  } else {
    const googleMapScript = document.createElement('script')
    window.document.body.appendChild(googleMapScript)
    googleMapScript.addEventListener('load', callback)
  }
}
const formatStatus = {
  pending: 'Pendiente',
  completed: 'Completado',
  confirmed: 'Asignado',
  canceled: 'Cancelado',
  arrived: 'En Ruta',
  sending: 'En Ruta',
  reserved: 'Reservado'
}
const statusDone = (data) => data?.status !== 'completed' && data?.status !== 'canceled'

export default function OrderDetail (props) {
  const classes = useStyles()
  const history = useNavigate()
  const dispatch = useDispatch()
  const order = useSelector(orderSelector)
  const [openEdit, setOpenEdit] = useState(false)
  const [searchPoint, setSearchPoint] = useState('')
  const [loadMap, setLoadMap] = useState(false)
  const { idPedido } = useParams()
  /* TODO: Uncomment this when socket can be used again */
  // const websocket = useWebsocket(idPedido);
  const handleClickOpen = () => {
    setOpenEdit(true)
  }
  const handleClose = () => {
    setOpenEdit(false)
  }
  const handleOrders = () => {
    if (order) {
      if (order.service === 'express') {
        history('/dashboard/express')
      } else {
        history('/dashboard/pedidos')
      }
    }
  }

  const handleSearch = (event) => {
    setSearchPoint(event.target.value)
  }

  const loadOrder = () => {
    const payload = { id: idPedido }
    dispatch(orderActions.getOrder({ payload }))
  }

  useEffect(() => {
    loadOrder()
    dispatch(orderActions.getZonas())
    loadGoogleMapScript(() => {
      setLoadMap(true)
    })
  }, [dispatch])

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {order && (
            <Button variant='contained' className={classes.button} onClick={handleOrders}>
              <ArrowBackRoundedIcon />
            </Button>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2 className='textTitle'>ID pedido: {order?._id}</h2>
            <Tooltip title='Actualizar pedido' aria-label='add'>
              <IconButton
                size='small'
                style={{
                  backgroundColor: '#FFFF',
                  color: 'green',
                  padding: '5px',
                  borderRadius: '50%',
                  marginLeft: '35px',
                  height: '35px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                }}
                onClick={() => loadOrder()}
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </div>
          <h4 className='textTitle'>
            {formatStatus[order?.status]} - Fecha de registro{' '}
            {moment(order?.createdAt).format('LLLL')}
          </h4>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
          <CardDetail order={order} reloadOrder={loadOrder} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
          {!loadMap ? (
            <div>Loading...</div>
          ) : (
            order?.points.length > 0 && <Map points={order?.points} order={order} />
          )}
        </Grid>

        <Grid container className='gridPuntos'>
          <Grid item md={2} lg={4} xl={4}>
            <hr style={{ marginTop: '2%' }} className='line' />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
            <h2 className='textTitle'>{order?.points.length - 1} puntos de entrega</h2>
          </Grid>
          <Grid item md={2} lg={4} xl={4}>
            <hr style={{ marginTop: '2%' }} className='line' />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <TextField
                size='small'
                variant='outlined'
                label='Buscar'
                value={searchPoint}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon style={{ color: '#0a50e6' }} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </div>
            {order?.status !== 'canceled' && (
              <Button
                variant='contained'
                size='medium'
                className={classes.button}
                onClick={handleClickOpen}
              >
                Agregar Puntos
              </Button>
            )}
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <Points reloadOrder={loadOrder} points={order?.points} order={order} />
        </Grid>
        <Grid item xs={12} className={classes.gridCancel}>
          {statusDone(order) && <DeleteOrder id={idPedido} />}
        </Grid>
      </Grid>
      <NotificationContainer />
      <AddPoint openEdit={openEdit} edit={false} handleClose={handleClose} />
    </div>
  )
}
