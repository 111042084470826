import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  styled,
  TableBody,
  TableRow,
  Table,
  TableSortLabel
} from '@mui/material'
import { useEffect, useState } from 'react'
import { PARAMS_BASED_ON_HEADERS } from '../constants'
import { rowSwitcherForClients, rowSwitcherForOrders, rowSwitcherForPartners } from './cellsHelper'

export const StyledTableCell = styled(TableCell)({
  padding: '3px 5px'
})

const getFunctionByType = (header, document, action, type = 'order') => {
  switch (type) {
    case 'order':
      return rowSwitcherForOrders(header, document, action)
    case 'client':
      return rowSwitcherForClients(header, document, action)
    case 'partner':
      return rowSwitcherForPartners(header, document, action)
  }
}
const returnNumberBasedOnCondition = (condition) => (condition ? 1 : -1)

const sortableHeaders = ['Driver', 'Order', 'Contacto Origen', 'Marca', 'Estado']

export const CommonTable = ({ headers = [], docs = [], action, type }) => {
  const [orderDirection, setOrderDirection] = useState('asc')
  const [docsOrdered, setDocsOrdered] = useState(docs)

  const sortStringsArray = (stringsArray, header) => {
    const sortedArray = stringsArray.sort((a, b) => {
      const x = a[PARAMS_BASED_ON_HEADERS[header]].toLowerCase()
      const y = b[PARAMS_BASED_ON_HEADERS[header]].toLowerCase()
      return orderDirection === 'asc'
        ? returnNumberBasedOnCondition(x > y)
        : returnNumberBasedOnCondition(x < y)
    })
    return sortedArray
  }
  // const sortNumbersArray = (numbersArray) => {
  //   const sortedArray = numbersArray.sort(function (a, b) {
  //     return orderDirection === 'asc' ? a - b : b - a
  //   })
  //   return sortedArray
  // }

  useEffect(() => {
    setDocsOrdered(docs)
  }, [docs])
  const handleSortDocs = (header) => {
    const newSortedDocs = sortStringsArray([...docs], header)
    setDocsOrdered(newSortedDocs)
    setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc')
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            {headers?.map((header) => {
              return sortableHeaders.includes(header) ? (
                <StyledTableCell
                  align='center'
                  key={`Title-Header${header}`}
                  sortDirection={orderDirection}
                >
                  <TableSortLabel direction={orderDirection} onClick={() => handleSortDocs(header)}>
                    {header}
                  </TableSortLabel>
                </StyledTableCell>
              ) : (
                <StyledTableCell key={`Title-Header${header}`} align='center' component='th'>
                  {header}
                </StyledTableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {docsOrdered.map((document) => (
            <TableRow key={document?.id}>
              {headers?.map((header) => (
                <StyledTableCell key={`Header-${header}`} align='center'>
                  {getFunctionByType(header, document, action, type)}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
