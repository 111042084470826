import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { useDispatch, useSelector } from 'react-redux'
import { partnersSelector } from '../../selectors/PartnersSelectors'
import * as partnersActions from '../../actions/PartnersActions'
import TextField from '@mui/material/TextField'
import './ModalPartner.scss'
import { IconButton, Tooltip } from '@mui/material'
import ListIcon from '@mui/icons-material/List'
import { getDatabase, ref, set } from 'firebase/database'
import * as ordersActions from '../../actions/OrdersActions'
import OrderController from '../../controllers/OrderController'
import { styled } from '@mui/material/styles'
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const StyledButton = styled(Button)({
  width: '80%',
  height: '2rem',
  backgroundColor: '#0a50e6',
  fontWeight: 'bold',
  fontSize: '11px',
  color: '#FFFF'
})
const LargeButtonToAssign = ({ rider = '', action }) => {
  return (
    <StyledButton onClick={action}>{rider ? 'Cambiar Driver' : 'Asignar Driver'}</StyledButton>
  )
}

const ButtonWithRiderName = ({ rider, action }) => {
  return (
    <div>
      <span>{rider}</span>
      <span>
        <Tooltip title='Cambiar Driver'>
          <IconButton variant='contained' onClick={action}>
            <ListIcon />
          </IconButton>
        </Tooltip>
      </span>
    </div>
  )
}
export default function ModalPartner ({
  orderId,
  hasDetail = false,
  assigned = false,
  rider = '',
  isExpress = false,
  reloadOrder
}) {
  const dispatch = useDispatch()
  const data = useSelector(partnersSelector)
  // useEffect(() => {
  //   let search = ``;
  //   dispatch(
  //     partnersActions.getPartners({ payload: { page: 1, data: search } })
  //   );
  // }, [dispatch]);
  const [search, setSearch] = useState()
  const [open, setOpen] = useState(false)
  const [errors, setErrors] = useState('')
  const handleSearch = (event) => {
    setSearch(event.target.value)
    const search = `search=${event.target.value}`
    dispatch(partnersActions.getPartners({ payload: { page: 1, data: search } }))
  }

  const newState = {
    page: 1,
    data: {
      action: 'pending'
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setErrors('')
    setOpen(false)
  }

  const assignOrder = (partner) => {
    const database = getDatabase()
    set(ref(database, 'partner_location_updates/' + partner.id), {
      state: 'busy',
      orderId
    })
      .then(() => {
        console.log('partener updated')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const assignPartner = async (partner) => {
    try {
      const payload = {
        orderId,
        partner: {
          partner: partner.id
        }
      }
      assignOrder(partner)
      await OrderController.assignPartner(payload)
      if (isExpress) {
        dispatch(ordersActions.getOrdersExpress({ payload: newState }))
      } else {
        dispatch(ordersActions.getOrdersFilters({ payload: newState }))
      }
      if (hasDetail) reloadOrder()
      setOpen(false)
    } catch (err) {
      setErrors(err.response.data.message)
    }
  }
  return (
    <div className='button__container'>
      {hasDetail && <LargeButtonToAssign rider={rider} action={handleClickOpen} />}
      {assigned ? (
        <ButtonWithRiderName rider={rider} action={handleClickOpen} />
      ) : !hasDetail && (
        <LargeButtonToAssign action={handleClickOpen} />
      )}
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>{'Seleccionar driver'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            <div>
              <TextField
                id='outlined-basic'
                label='Buscar'
                variant='outlined'
                size='small'
                value={search}
                onChange={handleSearch}
                style={{ width: '100%' }}
              />
            </div>
            <div>
              {data?.docs
                ?.filter((item) => !item.user.locked)
                .map((partner, index) => (
                  <div key={index} className='partners' onClick={() => assignPartner(partner)}>
                    <p>
                      <span>Nombre: </span> {partner.user.name.first}
                      <span> Apellido: </span> {partner.user.name.last}
                      <span> Vehículo: </span> {partner.vehicle}
                    </p>
                  </div>
                ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <div style={{ color: 'red', marginLeft: '50px' }}>{errors.length ? errors : ''}</div>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
