import React, { useState } from 'react'
import { withStyles, makeStyles } from '@mui/styles'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Card from '@mui/material/Card'
import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { useDispatch } from 'react-redux'
import * as orderActions from '../../actions/OrderActions'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: '2rem',
    top: '2rem',
    color: 'grey'
  }
})

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '2rem',
    minWidth: 200
  },
  selectEmpty: {
    marginTop: '2rem'
  },
  buttonBlue: {
    backgroundColor: '#0a50e6',
    color: 'white'
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  },
  color: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 10px'
  },
  button: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '10px 10px 20px 10px'
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 20px',
    margin: '10px 0px'
  },
  card: {
    padding: '3%',
    width: '40%'
  }
}))
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose
        ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
        >
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

export default function FormIncidence ({
  showIncidence,
  handleCloseIncidence,
  order,
  point
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [form, setForm] = useState({
    typeUpdate: '',
    motiveUpdate: '',
    amountCustumerUpdate: 0,
    amountMotorizedsUpdate: 0
  })
  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    })
  }

  // const [formErrors, setErrors] = useState({
  //   errorType: false,
  //   errorMotive: false,
  //   errorCustumer: false,
  //   errorMoto: false,
  // });

  const updatePoint = (e) => {
    e.preventDefault()
    const data = {
      idOrder: order?._id,
      idPoint: point?._id,
      typeUpdated: form.typeUpdate,
      categoryUpdated: 'Ruta',
      amountCustomerUpdated: form.amountCustumerUpdate * 100,
      amountMotorizedsUpdated: form.amountMotorizedsUpdate * 100,
      motiveUpdated: form.motiveUpdate
    }
    // mejorar la muestra y la recarga del modal
    dispatch(orderActions.updatePoint({ payload: data }))
    NotificationManager.success('Incidencia añadida correctamente', 'Incidencia')
    setTimeout(() => {
      handleCloseIncidence()
      window.location.reload()
    }, 1500)
  }
  return (
    <div>
      <Dialog
        onClose={handleCloseIncidence}
        aria-labelledby='customized-dialog-title'
        open={showIncidence}
      >
        <DialogTitle
          id='customized-dialog-title'
          onClose={handleCloseIncidence}
        >
          <h4>Incidencias</h4>
        </DialogTitle>
        <form onSubmit={updatePoint}>
          <div className={classes.color}>
            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='indicence__type__label'>Tipo</InputLabel>
              <Select
                labelId='indicence__type__label'
                id='incidence__type'
                value={form.typeUpdate}
                onChange={handleChange}
                label='Tipo'
                name='typeUpdate'
                required
              >
                <MenuItem value={'increase'}>Aumento</MenuItem>
                <MenuItem value={'decrease'}>Descuento</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant='outlined' className={classes.formControl}>
              <InputLabel id='indicence__reason__label'>Motivo</InputLabel>
              <Select
                labelId='indicence__reason__label'
                id='incidence__reason'
                value={form.motiveUpdate}
                onChange={handleChange}
                name='motiveUpdate'
                label='Motivo'
                required
              >
                <MenuItem value={'Tiempo de espera extra'}>
                  Tiempo de espera extra
                </MenuItem>
                <MenuItem value={'2do intento de entrega'}>
                  2do intento de entrega
                </MenuItem>
                <MenuItem value={'Retorno a tienda'}>Retorno a tienda</MenuItem>
                <MenuItem value={'Cancelacion fuera de plazo'}>
                  Cancelación fuera de plazo
                </MenuItem>
                <MenuItem value={'Otro'}>Otro</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.cardContainer}>
            <Card className={classes.card}>
              <h3>Cliente</h3>
              <FormControl>
                <InputLabel htmlFor='cliente'>Cantidad S/.</InputLabel>
                <Input
                  id='cliente'
                  type='number'
                  value={form.amountCustumerUpdate}
                  onChange={handleChange}
                  name='amountCustumerUpdate'
                />
              </FormControl>
            </Card>
            <Card className={classes.card}>
              <h3>Driver</h3>
              <FormControl>
                <InputLabel htmlFor='driver'>Cantidad S/.</InputLabel>
                <Input
                  id='driver'
                  type='number'
                  value={form.amountMotorizedsUpdate}
                  onChange={handleChange}
                  name='amountMotorizedsUpdate'
                />
              </FormControl>
            </Card>
          </div>
          <div className={classes.button}>
            <Button
              variant='contained'
              autoFocus
              type='submit'
              className={classes.buttonBlue}
          >
              Guardar
            </Button>
            <Button
              variant='contained'
              autoFocus
              onClick={handleCloseIncidence}
              color='secondary'
          >
              Cancelar
            </Button>
          </div>
        </form>
      </Dialog>
    </div>
  )
}
