import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/lab/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { partnersSelector } from '../../selectors/PartnersSelectors'
import * as partnersActions from '../../actions/PartnersActions'
import * as ordersActions from '../../actions/OrdersActions'

export default function SelectRider (props) {
  const [value, setValue] = React.useState(null)
  // const [id, setId] = React.useState(null);
  // const [inputValue, setInputValue] = React.useState('');
  const dispatch = useDispatch()
  const data = useSelector(partnersSelector)
  const dispatchExpress = (payloadData) => props.service === 'express'
    ? dispatch(ordersActions.getOrdersExpress({ payload: payloadData }))
    : dispatch(ordersActions.getOrdersFilters({ payload: payloadData }))
  // useEffect(() => {
  //   let search = ``;
  //   dispatch(partnersActions.getPartners({ payload:{page: 1, data: search} }));
  // }, [dispatch]);
  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        options={data?.docs}
        getOptionLabel={(option) =>
          `${option.user.name.first} ${option.user.name.last}`
        }
        id='movies'
        value={value}
        onChange={(event, newValue) => {
          const payloadFinal = {
            page: 1,
            data: {
              partner: ''
            }
          }
          if (newValue) {
            setValue(newValue)
            // setId(newValue.id);
            payloadFinal.data.partner = newValue.id
            dispatchExpress(payloadFinal)
          }
          props.payloadFunc(payloadFinal)
        }}
        onInputChange={(event) => {
          const search = `search=${event.target.value}`
          const payloadFinal = {
            page: 1,
            data: search
          }
          dispatch(partnersActions.getPartners({ payload: payloadFinal }))
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            style={{ margin: 0 }}
            label='Drivers'
            margin='normal'
            variant='outlined'
          />
        )}
      />
    </div>
  )
}
