import { useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import { useNavigate } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import '../../assets/css/formEdit.scss'
import { useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import PartnersController from '../../controllers/PartnersController'
import SnackBar from '../Modals/SnackBar'
const CreatePartner = (props) => {
  // const [errores, setErrores] = useState();
  const [type, setType] = useState()
  const [message, setMessage] = useState()
  const [open, setOpen] = useState()
  const handleClose = () => {
    setOpen(false)
  }
  const history = useNavigate()
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    documentType: '',
    documentNumber: '',
    ruc: '',
    plate: '',
    bankName: '',
    bankAccount: '',
    bankCCI: '',
    phone: '',
    location: {
      coordinates: []
    },
    vehicle: ''
  })
  // función para añadir todo dentro del form
  const handleChange = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value })
  }

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const validateDocument = (doc, number) => {
    if (doc === 'DNI' && number.length === 8) {
      return true
    }
    if (doc === 'Carné de Extranjería' && number.length === 9) {
      return true
    }
  }
  const sendPartner = async (e) => {
    // recodar poner formato que recibe la api
    if (!Object.keys(errors).length) {
      const payload = {
        user: {
          name: { first: form.firstName, last: form.lastName },
          email: form.email,
          password: form.password,
          locked: null
        },
        documentType: form.documentType,
        documentNumber: form.documentNumber,
        ruc: form.ruc,
        plate: form.plate,
        bankName: form.bankName,
        bankAccount: form.bankAccount,
        bankCCI: form.bankCCI,
        phone: form.phone,
        location: {
          coordinates: []
        },
        vehicle: form.vehicle
      }
      try {
        await PartnersController.postPartner(payload)
        setType('success')
        setMessage('Driver creado correctamente')
        setOpen(true)
        setTimeout(() => {
          history('/dashboard/drivers')
        }, 2000)
      } catch (err) {
        // setErrores(err.response.data);
        setOpen(true)
        setType('error')
        setMessage('Error al crear')
      }
    }
  }
  const cancelButton = () => {
    history('/dashboard/drivers')
  }
  return (
    <div>
      <form className='form__register' onSubmit={handleSubmit(sendPartner)}>
        <h1>Añadir nuevo driver</h1>
        <br />
        <br />
        <div>
          <InputLabel className='label__input' htmlFor='firstName'>
            Nombre
          </InputLabel>
          <TextField
            name='firstName'
            {...register('firstName', {
              required: 'Campo requerido',
              pattern: {
                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/,
                message: 'Solo se permiten letras'
              }
            })}
            className='input__form'
            id='firstName'
            password
            value={form.firstName}
            onChange={handleChange('firstName')}
            variant='outlined'
            label='Ingresa el nombre del driver'
          />
          {errors.firstName && <p>{errors.firstName.message}</p>}
        </div>
        <div>
          <InputLabel className='label__input' htmlFor='lastName'>
            Apellidos
          </InputLabel>
          <TextField
            name='lastName'
            {...register('lastName', {
              required: 'Campo requerido',
              pattern: {
                value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/,
                message: 'Solo se permiten letras'
              }
            })}
            className='input__form'
            value={form.lastName}
            onChange={handleChange('lastName')}
            variant='outlined'
            label='Ingresa los apellidos de los drivers'
          />
        </div>
        {errors.lastName && <p>{errors.lastName.message}</p>}
        <div>
          <InputLabel className='label__input' htmlFor='phone'>
            Celular
          </InputLabel>
          <TextField
            name='phone'
            {...register('phone', {
              required: 'Campo requerido',
              validate: {
                isANumber: (value) =>
                  !isNaN(value) || 'Solo se admiten números',
                defaultValue: (value) =>
                  value.length === 9 || 'Tienes que ingresar 9 números'
              }
            })}
            className='input__form'
            id='phone'
            value={form.phone}
            onChange={handleChange('phone')}
            variant='outlined'
            label='Ingresa el teléfono del driver'
          />
          {errors.phone && <p>{errors.phone.message}</p>}
        </div>
        <div>
          <InputLabel className='label__input' htmlFor='email'>
            Email
          </InputLabel>
          <TextField
            name='email'
            {...register('email', {
              required: 'Campo requerido',
              pattern: {
                value:
                  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                message: 'El email es inválido'
              }
            })}
            className='input__form'
            id='email'
            value={form.email}
            onChange={handleChange('email')}
            variant='outlined'
            label='Ingresa el email del driver'
            type='email'
          />
          {errors.email && <p>{errors.email.message}</p>}
        </div>
        <div>
          <InputLabel className='label__input' htmlFor='password'>
            Contraseña
          </InputLabel>
          <TextField
            name='password'
            {...register('password', {
              required: 'Contraseña obligatoria',
              minLength: {
                value: 6,
                message: 'La contraseña no puede ser menor de 6 caracteres'
              }
            })}
            className='input__form'
            id='password'
            value={form.password}
            onChange={handleChange('password')}
            variant='outlined'
            label='Ingresa la contraseña del driver'
          />
          {errors.password && <p>{errors.password.message}</p>}
        </div>
        <div>
          <InputLabel className='label__input' htmlFor='documentType'>
            Tipo de Documento
          </InputLabel>
          <Select
            name='documentType'
            {...register('documentType', { required: 'Campo requerido' })}
            variant='outlined'
            className='input__form'
            labelId='documentType'
            id='documentType'
            value={form.documentType}
            onChange={handleChange('documentType')}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'DNI'}>DNI</MenuItem>
            <MenuItem value={'Carné de Extranjería'}>
              Carné de Extranjería
            </MenuItem>
          </Select>
          {errors.documentType && <p>{errors.documentType.message}</p>}
        </div>
        <div>
          <InputLabel className='label__input' htmlFor='documentNumber'>
            Nº de Documento
          </InputLabel>
          <TextField
            name='documentNumber'
            {...register('documentNumber', {
              required: 'Campo requerido',
              minLength: {
                value: 8,
                message: 'El numero de caracteres no puede ser menor de 8'
              },
              maxLength: {
                value: 9,
                message: 'El numero de caracteres no puede ser mayor de 9'
              },
              validate: {
                IsANumber: (value) =>
                  !isNaN(value) || 'Solo se admiten números',
                validateTypeDocument: (value) =>
                  validateDocument(form.documentType, value) ||
                  `Ingresa la cantidad de numeros correctos para el ${form.documentType}`
              }
            })}
            className='input__form'
            id='documentNumber'
            value={form.documentNumber}
            onChange={handleChange('documentNumber')}
            variant='outlined'
            label='Ingresa el número de documento del driver'
          />
        </div>
        {errors.documentNumber && <p>{errors.documentNumber.message}</p>}
        <div>
          <InputLabel className='label__input' htmlFor='ruc'>
            RUC
          </InputLabel>
          <TextField
            name='ruc'
            {...register('ruc', {
              required: 'Campo requerido',
              validate: {
                IsANumber: (value) =>
                  !isNaN(value) || 'Solo se admiten números',
                valueLength: (value) =>
                  value.length === 11 || 'Tienes que ingresar 11 dígitos'
              }
            })}
            className='input__form'
            id='ruc'
            value={form.ruc}
            onChange={handleChange('ruc')}
            variant='outlined'
            label='Ingresa el RUC del driver'
          />
        </div>
        {errors.ruc && <p>{errors.ruc.message}</p>}
        <div>
          <InputLabel className='label__input' htmlFor='plate'>
            Placa del Vehículo
          </InputLabel>
          <TextField
            name='plate'
            {...register('plate', { required: 'Campo requerido' })}
            className='input__form'
            id='plate'
            value={form.plate}
            onChange={handleChange('plate')}
            variant='outlined'
            label='Ingresa la placa del vehículo del driver'
          />
        </div>
        {errors.plate && <p>{errors.plate.message}</p>}
        <div>
          <InputLabel className='label__input' htmlFor='vehicle'>
            Tipo de Vehículo
          </InputLabel>
          <Select
            name='vehicle'
            {...register('vehicle', { required: 'Campo requerido' })}
            variant='outlined'
            className='input__form'
            labelId='vehicle'
            id='vehicle'
            value={form.vehicle}
            onChange={handleChange('vehicle')}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            <MenuItem value={'moto'}>Moto</MenuItem>
            <MenuItem value={'auto'}>Auto</MenuItem>
          </Select>
          {errors.vehicle && <p>{errors.vehicle.message}</p>}
        </div>
        <div>
          <InputLabel className='label__input' htmlFor='bankName'>
            Entidad Bancaria
          </InputLabel>
          <TextField
            name='bankName'
            {...register('bankName', { required: 'Campo requerido' })}
            className='input__form'
            id='bankName'
            value={form.bankName}
            onChange={handleChange('bankName')}
            variant='outlined'
            label='Ingresa la entidad bancaria del driver'
          />
        </div>
        {errors.bankName && <p>{errors.bankName.message}</p>}
        <div>
          <InputLabel className='label__input' htmlFor='bankAccount'>
            Nº de cuenta
          </InputLabel>
          <TextField
            name='bankAccount'
            {...register('bankAccount', { required: 'Campo requerido' })}
            className='input__form'
            id='bankAccount'
            value={form.bankAccount}
            onChange={handleChange('bankAccount')}
            variant='outlined'
            label='Ingresa el número de cuenta del driver'
          />
          {errors.bankAccount && <p>{errors.bankAccount.message}</p>}
        </div>
        <div>
          <InputLabel className='label__input' htmlFor='bankCCI'>
            Código de Cuenta Interbancario (CCI)
          </InputLabel>
          <TextField
            name='bankCCI'
            {...register('bankCCI', { required: 'Campo requerido' })}
            className='input__form'
            id='bankCCI'
            value={form.bankCCI}
            onChange={handleChange('bankCCI')}
            variant='outlined'
            label='Ingresa el CCI del driver'
          />
          {errors.bankCCI && <p>{errors.bankCCI.message}</p>}
        </div>
        <div className='button__div'>
          <button className='button__register' type='submit'>
            Registrar
          </button>
          <Button
            className='button__form'
            variant='contained'
            onClick={cancelButton}
          >
            Cancelar
          </Button>
        </div>
        <SnackBar
          type={type}
          message={message}
          openValue={open}
          handleClose={handleClose}
        />
      </form>
    </div>
  )
}

export default CreatePartner
