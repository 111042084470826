import React, { useState } from 'react'
import { withStyles, makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import * as orderActions from '../../actions/OrderActions'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: '2rem',
    top: '2rem',
    color: 'gray'
  }
})

const useStyles = makeStyles(() => ({
  buttonBlue: {
    backgroundColor: '#0a50e6',
    color: 'white'
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  },
  color: {
    color: '#3F4D55',
    padding: '5px 20px',
    '& span': {
      marginTop: '15px',
      display: 'flex',
      justifyContent: 'center'
    },
    '& h2': {
      margin: 0
    }
  },
  button: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '20px 10px 20px 10px'
  }
}))
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose
        ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
        >
            <CloseIcon />
          </IconButton>
          )
        : null}
    </MuiDialogTitle>
  )
})

export default function DeleteOrder ({ id }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const cancelOrder = () => {
    try {
      const payload = { id }
      dispatch(orderActions.cancelOrder({ payload }))
      dispatch(orderActions.getOrder({ payload }))
      setOpen(false)
      NotificationManager.success('Orden eliminada exitosamente', 'Orden')
    } catch (e) {
      console.log(e)
    }

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1500);
  }
  return (
    <div>
      <Button
        size='small'
        variant='contained'
        color='error'
        onClick={handleClickOpen}
      >
        Cancelar el Pedido
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          <DeleteForeverIcon color='error' style={{ fontSize: 120 }} />
        </DialogTitle>
        <div className={classes.color}>
          <h2>¿Estás seguro de eliminar todo el pedido?</h2>
          <span>No podrás revertir esta acción</span>
        </div>

        <div className={classes.button}>
          <Button
            variant='contained'
            autoFocus
            onClick={cancelOrder}
            className={classes.buttonBlue}
          >
            Sí, cancelar
          </Button>
          <Button
            variant='contained'
            autoFocus
            onClick={handleClose}
            color='error'
          >
            No
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
