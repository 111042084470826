import React, { useState } from 'react'
import './styles.scss'
import SnackBar from '../Modals/SnackBar'
import ServicesSwap from './ServicesSwap'
import AddMoreKmRadious from './AddMoreKmRadious'

export const buttonLoadingStyles = (loading) => {
  return {
    backgroundColor: loading ? 'rgba(0, 0, 0, 0.26)' : '#0a50e6',
    color: 'white',
    fontSize: '16px'
  }
}
const Settings = () => {
  const [snackBarData, setSnackBarData] = useState({
    message: '',
    type: '',
    show: false
  })

  const clearSnackBar = () => {
    setSnackBarData({
      message: '',
      type: '',
      show: false
    })
  }
  return (
    <div className='settings__container'>
      <div style={{ marginBottom: '2rem' }}>
        <h1 className='header__title'>Configuración</h1>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem'
        }}
      >
        <AddMoreKmRadious
          setSnackBarData={setSnackBarData}
          snackBarData={snackBarData}
          clearSnackBar={clearSnackBar}
        />
        <div className='divider__settings'></div>
        <ServicesSwap
          snackBarData={snackBarData}
          setSnackBarData={setSnackBarData}
          clearSnackBar={clearSnackBar}
        />
      </div>
      <SnackBar
        message={snackBarData.message}
        type={snackBarData.type}
        openValue={snackBarData.show}
        handleClose={() => setSnackBarData({ ...snackBarData, show: false })}
      />
    </div>
  )
}

export { Settings }
