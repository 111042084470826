import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles } from '@mui/styles'
import * as XLSX from 'xlsx'
import OrdersController from '../../controllers/OrdersController'
import moment from 'moment'
import 'moment/locale/es'
import TextField from '@mui/material/TextField'
import SaveIcon from '@mui/icons-material/Save'
import IconButton from '@mui/material/IconButton'
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material'
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: '1rem',
    marginRight: '1rem',
    width: 200
  }
}))

export default function Export () {
  const [open, setOpen] = useState(false)
  const [isPendingAdded, setIsPendingAdded] = useState(false)
  const classes = useStyles()
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const formatPayment = {
    card: 'tarjeta',
    credit: 'crédito'
  }
  const restPrice = (price) => {
    // cambiar tiempo mayor a 10 minutos cuando se lance a produccion
    if (price >= 10) {
      return (price - 10) * 0.45
    }
    return 0
  }
  const exportReport = async () => {
    try {
      const params = {
        from,
        to,
        status: isPendingAdded ? 'canceled,completed,pending' : 'canceled,completed'
      }
      const response = await OrdersController.getExport(params)
      const datos = response.data
      const getColumns = [
        // Order cols
        'ID',
        'PEDIDO',
        'FEHA DE PEDIDO',
        'RAZÓN SOCIAL',
        'RUC',
        'EMAIL',
        'PAGO',
        'TIPO DE SERVICIO',
        'DRIVER',
        'EMAIL DRIVER',
        'PAGO DRIVER (S/.)',
        'FACTURABLE DRIVER (S/.)',
        // Point cols
        'DESTINOS',
        'FECHA FINALIZACION',
        'INSTRUCCIONES DEL CLIENTE',
        'RECIBIDO POR/MOTIVO',
        'DETALLE DEL RIDER',
        'ESTADO DE CADA PUNTO',
        'DISTANCIA',
        'TIEMPO DE ESPERA',
        'TARIFA (S/.)',
        'FACTURABLE (S/.)',
        // Order cols
        'SUBTOTAL',
        'TIEMPO TOTAL DE ESPERA',
        'TOTAL (S/.)',
        'TOTAL FACTURABLE (S/.)',
        'VEHICULO',
        'DISTANCIA'
      ]
      const rows = [getColumns]
      let rowIdx = rows.length - 1
      const merges = []
      for (const o of datos) {
        const orderInfo = {
          0: o._id,
          1: o.number,
          2: moment(o.createdAt).format('DD/MM/YYYY hh:mm A'),
          3: temporalNameParser(o.customer),
          4: (o.customer && o.customer.company.ruc) || '-',
          5: (o.customer && o.customer.user.email) || '-',
          6: formatPayment[o.paymentUsed],
          7: o.service === 'programmed' ? 'NextDay' : o.service,
          8: o.partner ? name(o.partner.user.name) : '-',
          9: o.partner ? o.partner.user.email : '-',
          10: o.partnerProfit / 100,
          11: o.status === 'completed' ? o.partnerProfit / 100 : 0,
          22: o.amount / 100 - restPrice(o.points.reduce((a, b) => a + b.waitTime, 0)),
          23: o.points.reduce((a, b) => a + b.waitTime, 0),
          24: o.amount / 100,
          25: o.points.reduce(
            (sum, p) => sum + (p.statePoint === 'Entregado' ? (p.price || 0) / 100 : 0),
            0
          ),
          26: o.vehicle,
          27: !isNaN((o.distance / 100).toFixed(2)) ? (o.distance / 100).toFixed(2) : 0
        }
        const pointsInfos = o.points.map((p) => ({
          12: p.address,
          13: moment(p.done).format('DD/MM/YYYY hh:mm A'),
          14: p.instructions,
          15: p.chargeReceivedBy,
          16: p.descriptionPoint,
          17: p.statePoint,
          18: o.service === 'express' ? p.distance : '',
          19: p.waitTime,
          20: (p.price || 0) / 100,
          21: p.done ? (p.price || 0) / 100 : 0
        }))

        const orderRows = pointsInfos.map((pointsInfo, i) => [
          orderInfo[0],
          orderInfo[1],
          orderInfo[2],
          orderInfo[3],
          orderInfo[4],
          orderInfo[5],
          orderInfo[6],
          orderInfo[7],
          orderInfo[8],
          orderInfo[9],
          i ? 0 : orderInfo[10],
          i ? 0 : orderInfo[11],
          pointsInfo[12],
          pointsInfo[13],
          pointsInfo[14],
          pointsInfo[15],
          pointsInfo[16],
          pointsInfo[17],
          pointsInfo[18],
          pointsInfo[19],
          pointsInfo[20],
          pointsInfo[21],
          i ? 0 : orderInfo[22],
          i ? 0 : orderInfo[23],
          orderInfo[24],
          orderInfo[25],
          orderInfo[26],
          orderInfo[27]
        ])

        for (const col of Object.keys(orderInfo)) {
          merges.push({
            s: { r: rowIdx + 1, c: Number.parseInt(col) },
            e: { r: rowIdx + o.points.length, c: Number.parseInt(col) }
          })
        }

        rows.push(...orderRows)
        rowIdx += o.points.length
      }

      const tsFrom = from
      const tsTo = to
      const ws = XLSX.utils.aoa_to_sheet(rows)
      ws['!merges'] = merges
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Reporte')
      XLSX.writeFile(wb, `orders-${tsFrom}-${tsTo}.xlsx`)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Tooltip title='Exportar pedidos' aria-label='add'>
        <IconButton
          variant='contained'
          onClick={handleClickOpen}
          style={{
            borderRadius: '0px 5px 5px 0px',
            padding: '18px 55%',
            color: 'rgba(0, 0, 0, 0.87)',
            backgroundColor: 'rgb(255, 195, 0)',
            height: '56px'
          }}
        >
          <SaveIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id='responsive-dialog-title'>
          Selecciona el rango de fecha de tu reporte.
        </DialogTitle>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div>
            <TextField
              id='date'
              label='Desde'
              type='date'
              defaultValue={new Date()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              size='small'
              variant='outlined'
              onChange={(e) => setFrom(e.target.value)}
            />
            <TextField
              id='date'
              label='Hasta'
              type='date'
              defaultValue={new Date()}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              size='small'
              variant='outlined'
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPendingAdded}
                  onChange={(event) => setIsPendingAdded(event.target.checked)}
                />
              }
              label='Añadir envíos pendientes'
            />
          </div>
        </div>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            onClick={exportReport}
            variant='contained'
            size='small'
            style={{
              backgroundColor: from === '' && to === '' ? '#DFDCDB' : '#084c71',
              color: 'white',
              height: '40px',
              width: '80px',
              marginLeft: '20px'
            }}
            disabled={from === '' && to === ''}
          >
            Exportar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export function name (name) {
  return typeof name === 'object' ? name.first + ' ' + name.last : name
}

function temporalNameParser (customer) {
  if (!customer) {
    return 'CLIENTE ELIMINADO'
  }

  if (['--', ''].includes(customer.company.name)) {
    return name(customer.user.name)
  } else {
    return customer.company.name
  }
}
