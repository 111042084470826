import React, { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import '../../assets/css/formEdit.scss'
import { useForm } from 'react-hook-form'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import PartnersController from '../../controllers/PartnersController'
import SnackBar from '../Modals/SnackBar'
import { Checkbox, Grid } from '@mui/material'
import { ref, update } from 'firebase/database'
import { db } from '../../firebase'

const EditPartner = () => {
  let unsubscribeMap = () => {}

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const setIsLocked = (data) => {
    if (data) {
      setLocked('false')
    } else {
      setLocked('activo')
    }
  }
  const { idDriver } = useParams()
  const [locked, setLocked] = useState('')
  const [type, setType] = useState('')
  const [message, setMessage] = useState('')
  const [isDoc, setIsDoc] = useState('')
  const [isVehicle, setIsVehicle] = useState('')
  const history = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm()

  const load = async () => {
    try {
      const objectPartner = await PartnersController.getPartner(idDriver)
      setValue('firstName', objectPartner?.user.name.first)
      setValue('lastName', objectPartner?.user.name.last)
      setValue('email', objectPartner?.user.email)
      setValue('locked', objectPartner?.user.locked)
      setIsLocked(objectPartner?.user.locked)
      setIsDoc(
        objectPartner?.documentType === 'DNI' ||
          objectPartner?.documentType === 'Carné de extranjería'
          ? objectPartner?.documentType
          : 'DNI'
      )
      setValue('documentNumber', objectPartner?.documentNumber)
      setValue('ruc', objectPartner?.ruc)
      setValue('plate', objectPartner?.plate)
      setValue('bankName', objectPartner?.bankName)
      setValue('bankAccount', objectPartner?.bankAccount)
      setValue('bankCCI', objectPartner?.bankCCI)
      setValue('phone', objectPartner?.phone)
      setIsVehicle(objectPartner?.vehicle)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    load()
    return () => unsubscribeMap()
  }, [])

  const validateDocument = (doc, number) => {
    if (doc === 'DNI' && number.length === 8) {
      return true
    } else if (doc === 'Carné de extranjería' && number.length === 9) {
      return true
    }
    return false
  }
  const handleLockedChange = () => {
    setLocked(locked === 'activo' ? 'false' : 'activo')
  }
  const handleDoc = () => {
    setIsDoc(isDoc === 'DNI' ? 'Carné de extranjería' : 'DNI')
  }
  const handleVehicle = () => {
    setIsVehicle(isVehicle === 'moto' ? 'auto' : 'moto')
  }

  const verificateEmptyValues = (data) => {
    Object.entries(data.user).map((element) => {
      if (element[0] !== 'locked') {
        if (!element[1] || element[1] === null || element[1] === '') {
          delete data.user[element[0]]
        }
      }
    })
  }

  const lockedPartner = () => {
    unsubscribeMap = update(ref(db, `/partner_location_updates/${idDriver}`), { locked })
  }

  const sendPartner = async () => {
    // recodar poner formato que recibe la api
    if (!Object.keys(errors).length) {
      const payload = {
        user: {
          name: {
            first: getValues('firstName'),
            last: getValues('lastName')
          },
          email: getValues('email'),
          locked: locked === 'activo' ? '' : 'false',
          password: getValues('password')
        },
        documentType: isDoc,
        documentNumber: getValues('documentNumber'),
        ruc: getValues('ruc'),
        plate: getValues('plate'),
        bankName: getValues('bankName'),
        bankAccount: getValues('bankAccount'),
        bankCCI: getValues('bankCCI'),
        phone: getValues('phone'),
        vehicle: isVehicle,
        id: idDriver
      }
      // si la contraseña no varia, no se envía el dato
      verificateEmptyValues(payload)
      lockedPartner()
      try {
        await PartnersController.updatePartner(payload)
        setOpen(true)
        setType('success')
        setMessage('Driver editado exitosamente')
        setTimeout(() => {
          history('/dashboard/drivers')
        }, 2000)
      } catch (error) {
        // setErrorUpdate(error.response.data);
        setOpen(true)
        setType('error')
        setMessage('Error al editar')
      }
    }
  }
  const cancelButton = () => {
    history('/dashboard/drivers')
  }
  return (
    <div>
      <form className='form__register' onSubmit={handleSubmit(sendPartner)}>
        <h1>Editar driver</h1>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='firstName'>
                Nombre
              </InputLabel>
              <TextField
                name='firstName'
                {...register('firstName', {
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/,
                    message: 'Solo se permiten letras'
                  }
                })}
                className='input__form'
                id='firstName'
                variant='outlined'
                error={Boolean(errors.firstName)}
                helperText={errors.firstName ? errors.firstName.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='lastName'>
                Apellidos
              </InputLabel>
              <TextField
                name='lastName'
                id='lastName'
                {...register('lastName', {
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]+$/,
                    message: 'Solo se permiten letras'
                  }
                })}
                className='input__form'
                variant='outlined'
                error={Boolean(errors.lastName)}
                helperText={errors.lastName ? errors.lastName.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='phone'>
                Celular
              </InputLabel>
              <TextField
                name='phone'
                {...register('phone', {
                  required: 'Campo requerido',
                  minLength: {
                    value: 7,
                    message: 'Inserta al menos 7 dígitos'
                  },
                  maxLength: {
                    value: 9,
                    message: 'Se permite 9 dígitos cómo máximo'
                  },
                  validate: {
                    isANumber: (value) => !isNaN(value) || 'Solo se admiten números'
                  }
                })}
                className='input__form'
                id='phone'
                variant='outlined'
                error={Boolean(errors.phone)}
                helperText={errors.phone ? errors.phone.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='email'>
                Email
              </InputLabel>
              <TextField
                name='email'
                {...register('email', {
                  required: 'Campo requerido',
                  pattern: {
                    value: /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i,
                    message: 'El email es inválido'
                  }
                })}
                className='input__form'
                id='email'
                variant='outlined'
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='password'>
                Nueva Contraseña (opcional)
              </InputLabel>
              <TextField
                name='password'
                {...register('password', {
                  minLength: {
                    value: 6,
                    message: 'La contraseña no puede ser menor de 6 caracteres'
                  }
                })}
                className='input__form'
                id='password'
                variant='outlined'
                error={Boolean(errors.password)}
                helperText={errors.password ? errors.password.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='documentType'>
                Tipo de Documento
              </InputLabel>

              <RadioGroup
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '10px'
                }}
                aria-label='document'
                name='documentType'
                value={isDoc}
                onChange={handleDoc}
              >
                <FormControlLabel value='DNI' control={<Radio color='primary' />} label='DNI' />
                <FormControlLabel
                  value='Carné de extranjería'
                  control={<Radio color='secondary' />}
                  label='Carné de extranjería'
                />
              </RadioGroup>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='documentNumber'>
                Nº de Documento
              </InputLabel>
              <TextField
                name='documentNumber'
                {...register('documentNumber', {
                  required: 'Campo requerido',
                  minLength: {
                    value: 8,
                    message: 'El numero de caracteres no puede ser menor de 8'
                  },
                  maxLength: {
                    value: 9,
                    message: 'El numero de caracteres no puede ser mayor de 9'
                  },
                  validate: {
                    IsANumber: (value) => !isNaN(value) || 'Solo se admiten números',
                    validateTypeDocument: (value) =>
                      validateDocument(isDoc, value) ||
                      `Ingresa la cantidad de numeros correctos para el ${isDoc}`
                  }
                })}
                className='input__form'
                id='documentNumber'
                variant='outlined'
                error={Boolean(errors.documentNumber)}
                helperText={errors.documentNumber ? errors.documentNumber.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='ruc'>
                RUC
              </InputLabel>
              <TextField
                name='ruc'
                {...register('ruc', {
                  required: 'Campo requerido',
                  validate: {
                    IsANumber: (value) => !isNaN(value) || 'Solo se admiten números',
                    valueLength: (value) => value.length === 11 || 'Tienes que ingresar 11 dígitos'
                  }
                })}
                className='input__form'
                id='ruc'
                variant='outlined'
                error={Boolean(errors.ruc)}
                helperText={errors.ruc ? errors.ruc.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='plate'>
                Placa del Vehículo
              </InputLabel>
              <TextField
                name='plate'
                {...register('plate', { required: 'Campo requerido' })}
                className='input__form'
                id='plate'
                variant='outlined'
                error={Boolean(errors.plate)}
                helperText={errors.plate ? errors.plate.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div>
              <InputLabel className='label__input' htmlFor='vehicle'>
                Tipo de Vehículo
              </InputLabel>
              <RadioGroup
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: '10px'
                }}
                aria-label='vehic'
                name='vehicle'
                value={isVehicle}
                onChange={handleVehicle}
              >
                <FormControlLabel value='moto' control={<Radio color='primary' />} label='Moto' />
                <FormControlLabel value='auto' control={<Radio color='secondary' />} label='Auto' />
              </RadioGroup>
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='bankName'>
                Entidad Bancaria
              </InputLabel>
              <TextField
                name='bankName'
                {...register('bankName', { required: 'Campo requerido' })}
                className='input__form'
                id='bankName'
                variant='outlined'
                error={Boolean(errors.bankName)}
                helperText={errors.bankName ? errors.bankName.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='bankAccount'>
                Número de cuenta
              </InputLabel>
              <TextField
                name='bankAccount'
                {...register('bankAccount', { required: 'Campo requerido' })}
                className='input__form'
                id='bankAccount'
                variant='outlined'
                error={Boolean(errors.bankAccount)}
                helperText={errors.bankAccount ? errors.bankAccount.message : ''}
              />
            </div>
          </Grid>
        </Grid>
        <hr className='hr__divider' />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <InputLabel className='label__input' htmlFor='bankCCI'>
                Código de Cuenta Interbancario (CCI)
              </InputLabel>
              <TextField
                name='bankCCI'
                {...register('bankCCI', { required: 'Campo requerido' })}
                className='input__form'
                id='bankCCI'
                variant='outlined'
                error={Boolean(errors.bankCCI)}
                helperText={errors.bankCCI ? errors.bankCCI.message : ''}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className='input__checkbox'>
              <InputLabel className='label__input' htmlFor='locked'>
                Estado del driver
              </InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={locked !== 'activo'}
                    onChange={handleLockedChange}
                    name='locked'
                    color='primary'
                    value={false}
                  />
                }
                label='Bloqueado'
              />
            </div>
          </Grid>
        </Grid>

        <div className='button__div'>
          <button className='button__register' type='submit'>
            Editar
          </button>
          <Button className='button__form' variant='contained' onClick={cancelButton}>
            Cancelar
          </Button>
        </div>
        <SnackBar type={type} message={message} openValue={open} handleClose={handleClose} />
      </form>
    </div>
  )
}

export default EditPartner
