import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
const firebaseConfig = {
  apiKey: 'AIzaSyBCiTVeI_o0JtFCbeTNJCEypXmKRLTBYe0',
  authDomain: 'directo-logistics.firebaseapp.com',
  projectId: 'directo-logistics',
  storageBucket: 'directo-logistics.appspot.com',
  messagingSenderId: '854701664291',
  appId: '1:854701664291:web:e1e8dfc06e291059412996'
}
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const db = getDatabase(firebaseApp)
export { db }
