import React from 'react'
import { Provider } from 'react-redux'
import { store } from '.././store'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Private from './middlewares/Private'
import {
  ClientsPage,
  CreatePartnerPage,
  DashboardPage,
  EditClientPage,
  EditPartnerPage,
  LoginPage,
  MapPage,
  OrdersExpressPage,
  OrdersPage,
  PartnersPage,
  SettingsPage
} from '../pages'
import OrderDetail from '../components/Detail/OrderDetail'
import Header from '../components/Header'

const AppRouter = () => {
  const token = localStorage.getItem('token')
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              token ? <Navigate to='/dashboard/inicio' replace /> : <Navigate to='/login' replace />
            }
           />
          <Route path='/login' element={<LoginPage />} />
          <Route
            path='/dashboard'
            element={
              <Private>
                <Header />
              </Private>
            }
          >
            <Route path='inicio' element={<DashboardPage />} />
            <Route path='pedidos' element={<OrdersPage />} />
            <Route path='express' element={<OrdersExpressPage />} />
            <Route path='pedidos/:idPedido' element={<OrderDetail />} />
            <Route path='clientes' element={<ClientsPage />} />
            <Route path='drivers' element={<PartnersPage />} />
            <Route path='tiempo-real' element={<MapPage />} />
            <Route path='crear-driver' element={<CreatePartnerPage />} />
            <Route path='clientes/editar/:idCliente' element={<EditClientPage />} />
            <Route path='drivers/editar/:idDriver' element={<EditPartnerPage />} />
            <Route path='configuracion' element={<SettingsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default AppRouter
